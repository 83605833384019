<template>
  <a-page-header @back="() => $router.go(-1)" :title="tituloPagina" class="pt-0 px-0 uppercase">
    <template #extra>
      <a-breadcrumb>
        <a-breadcrumb-item
          ><router-link to="/menu" class="capitalize">Nutricional</router-link></a-breadcrumb-item
        >
        <a-breadcrumb-item
          ><span class="capitalize">{{ tituloPagina }}</span>
        </a-breadcrumb-item>
      </a-breadcrumb>
    </template>
  </a-page-header>

  <div class="flex flex-wrap mb-4">
    <!--------------- Filtros--------------->
    <fieldset
      class="border border-solid w-full mt-3 border-gray-300 p-3 px-8 md:w-1/2 md:mt-0 lg:w-auto"
    >
      <legend class="text-sm text-center">Filtros</legend>
      <GraficasFiltro @onFilterData="onFilterData" @fetchPoints="fetchDatos" />
    </fieldset>

    <!--------------- Acciones--------------->
    <fieldset class="border border-solid w-full border-gray-300 p-3 px-8 md:w-1/2 lg:w-auto">
      <legend class="text-sm text-center">Acciones</legend>
      <GraficasAcciones
        :isDisabled="isDisabled"
        :point="point"
        @handleAdd="handleAdd"
        @handleEdit="handleEdit"
        @handleDelete="handleDelete"
        @handleChangeState="handleChangeState"
        @handleShowGraphic="handleShowGraphic"
      />
    </fieldset>
  </div>

  <!--------------- Tabla --------------->
  <div class="max-w-full px-4 py-4 mx-auto overflow-x-auto">
    <a-table
      :columns="columns"
      :data-source="data"
      size="small"
      :row-selection="rowSelection"
      rowKey="idestadistico"
      :loading="isLoading"
      :rowClassName="(record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-gray-100')"
      :pagination="{ pageSize: 15 }"
      :custom-row="customRow"
    >
      <template #rangepages="{ index }">
        <span> {{ index + 1 }} </span>
      </template>

      <template #activo="{ text: activo }">
        <span>
          <a-tag :color="activo === '1' ? 'green' : 'volcano'">
            {{ activo === "1" ? "Activado" : "Desactivado" }}
          </a-tag>
        </span>
      </template>
    </a-table>
  </div>

  <footer class="flex flex-wrap items-center justify-between pb-6 pt-5 border-t-2">
    <div class="flex flex-wrap">
      <button class="ml-4" @click="fetchDatos">
        <ReloadOutlined />
      </button>
    </div>

    <span class="flex">
      <p>Total de activos: {{ totalActives }}</p>
      <span class="mx-3">|</span>
      <p>Total de registros: {{ totalRecords }}</p>
    </span>
  </footer>

  <a-modal
    :visible="isVisible && typemodal === 'graphic'"
    @close="closeModal"
    @cancel="handleCancel"
    :footer="null"
    class="max-w-sm md:max-w-2xl text-center"
    width="100%"
  >
    <div id="printNutricionalGrafica">
      <PrintHeader />
      <v-chart :option="grafico" class="w-full h-72 my-5" />
      <p class="-mt-12 text-right">Fuente: ENDES-INEI 2021</p>
    </div>

    <a-button
      v-print="'#printNutricionalGrafica'"
      type="primary"
      class="w-full text-center mx-auto"
    >
      Imprimir grafica
    </a-button>
  </a-modal>

  <p class="text-gray-400 my-4">Fuente: ENDES-INEI 2021</p>

  <GraficasAddEdit
    v-if="isVisible && typemodal !== 'graphic'"
    :idtipodeficiencia="idtipodeficiencia"
    :point="point"
    @closeModal="closeModal"
    @fetchPoints="fetchDatos"
  />
</template>

<script>
import { reactive, ref, computed, onMounted, toRaw, watch, createVNode } from "vue";
import { columns } from "./utilsGraficas";
import { ReloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { useRoute } from "vue-router";
import { Modal } from "ant-design-vue";
import { useStore } from "vuex";
import GraficasAddEdit from "./GraficasAddEdit.vue";
import GraficasAcciones from "./GraficasAcciones.vue";
import GraficasFiltro from "./GraficasFiltro.vue";
import PrintHeader from "@/components/PrintHeader.vue";
import EstadisticoApi from "@/api/estadistico";
import { genTwoPointsLines, generaGraficosLine } from "@/utils";
import UsuariosApi from "@/api/user";
import { optionGraphic } from "@/views/public/utilsWelcome";

export default {
  name: "AppGraficas",
  components: {
    GraficasFiltro,
    GraficasAddEdit,
    GraficasAcciones,
    PrintHeader,
    // icons
    ReloadOutlined
  },
  setup() {
    const isVisible = ref(false);
    const typemodal = ref("");
    const store = useStore();
    const isLoading = ref(false);
    const points = ref([]);
    const point = ref(null);
    const grafico = ref(null);
    const route = useRoute();
    const isPointActive = ref(null);
    const data = ref([]);
    const totalRecords = ref(0);
    const tipografica = route.params?.tipo;
    const isDisabled = reactive({
      edit: true,
      deleteAndChange: true
    });
    const state = reactive({
      selectedRowKeys: []
    });

    const { establecimiento } = store.state.user.usuario;

    const isDesnutricion = tipografica === "desnutricion";
    const idtipodeficiencia = isDesnutricion ? "1" : "2";

    // ------- mostrar modal -------
    const showModal = () => {
      isVisible.value = true;
    };

    const closeModal = () => {
      isVisible.value = false;
    };

    // ------- acciones -------

    const handleAdd = () => {
      typemodal.value = "handleadd";
      clearSelectedRowkey();
      point.value = {
        anio: "",
        valorlocal: "",
        valornacional: ""
      };
      showModal();
    };

    const handleEdit = () => {
      typemodal.value = "edit";
      showModal();
    };

    const handleDelete = () => {
      Modal.confirm({
        title: () => "¿Estás seguro de eliminar?",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => `Se eliminará el valor estadístico del año ${point.value.anio}`,
        okText: () => "Sí, eliminar",
        okType: "danger",
        cancelText: () => "Cancelar",
        onOk() {
          console.log(state.selectedRowKeys);

          EstadisticoApi.deleteOne(point.value.idestadistico)
            .then(() => {
              fetchDatos();
            })
            .catch((err) => console.log(err));
        }
      });
    };

    const handleChangeState = () => {
      const message = {
        anio: point.value.anio,
        activo: point.value.activo === "1" ? "desactivar" : "activar",
        activar: point.value.activo === "1" ? "desactivará " : "activará",
        texto: point.value.activo === "1" ? "ya no se mostrará" : "se mostrará",
        action: point.value.activo === "1" ? "Sí, desactivar" : "Sí, activar"
      };
      Modal.confirm({
        title: () => `¿Estás seguro de ${message.activo} el estado?`,
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () =>
          `Se ${message.activar} el punto estadístico del año ${message.anio}, ${message.texto} en el gráfico, este cambio afectará la vista pública.`,
        okText: () => message.action,
        okType: "danger",
        cancelText: () => "Cancelar",

        onOk() {
          const activeChanged = point.value.activo === "1" ? "0" : "1";
          EstadisticoApi.changeState(point.value.idestadistico, activeChanged)
            .then(() => {
              fetchDatos();
            })
            .catch((err) => console.log(err));
        }
      });
    };
    const handleShowGraphic = () => {
      typemodal.value = "graphic";
      showModal();
    };

    const tituloPagina = computed(() =>
      isDesnutricion ? "Gráfico desnutrición crónica" : "Gráfico anemia"
    );

    const tituloGrafica = computed(() =>
      isDesnutricion
        ? "% Niñas y niños < 5 años con desnutrición crónica, Tacna"
        : "% Niñas y niños de 6 a 35 meses de edad con anemia, Tacna"
    );

    const fetchDatos = (filter = "all") => {
      isLoading.value = true;

      EstadisticoApi.getAll(idtipodeficiencia, filter)
        .then(async (response) => {
          console.log("EstadisticoApi ", response);
          data.value = response.data;
          totalRecords.value = response.data.length;
          points.value = await genTwoPointsLines(response.data);

          const valoresGraficas = generaGraficosLine(response.data);
          valoresGraficas.download = true;
          grafico.value = optionGraphic(tituloGrafica, valoresGraficas);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          isLoading.value = false;
          clearSelectedRowkey();
        });
    };

    const totalActives = computed(() => data.value.filter((i) => i.activo === "1").length);

    const onFilterData = (filtro) => {
      fetchDatos(filtro);
    };

    onMounted(() => {
      UsuariosApi.userLogAdd("Nutricional", "Gráficas", establecimiento)
        .then(() => {})
        .catch((err) => console.log(err));

      fetchDatos();
    });

    // -------- se ejecuta cuando selecciona una fila --------
    const rowSelection = computed(() => {
      return {
        type: "radio",
        selectedRowKeys: state.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          state.selectedRowKeys = selectedRowKeys;
          point.value = toRaw(selectedRows[0]);
        }
      };
    });

    // ------- desactiva la seleccion actual -------
    const customRow = () => {
      return {
        onDblclick: () => {
          clearSelectedRowkey();
        }
      };
    };

    const clearSelectedRowkey = () => {
      state.selectedRowKeys = [];
    };

    watch(state, () => {
      isDisabled.edit = !state.selectedRowKeys.length;
      isDisabled.deleteAndChange = !state.selectedRowKeys.length;
    });

    const handleCancel = () => {
      closeModal();
    };

    return {
      // routes,
      // goBack,
      columns,
      isDisabled,
      typemodal,
      closeModal,
      isLoading,
      handleAdd,
      handleEdit,
      handleDelete,
      handleChangeState,
      handleShowGraphic,
      isVisible,
      tipografica,
      tituloPagina,
      tituloGrafica,
      data,
      totalRecords,
      handleCancel,
      points,
      point,
      rowSelection,
      customRow,
      isPointActive,
      idtipodeficiencia,
      fetchDatos,
      totalActives,
      onFilterData,
      grafico
    };
  }
};
</script>

<style></style>
