<template>
  <a-modal
    :visible="true"
    :title="[isEdit ? 'Editar punto de gráfico' : 'Nuevo punto de gráfico']"
    class="max-w-sm"
    @ok="handleOk"
    @close="closeModal"
    @cancel="handleCancel"
    okText="Guardar"
    :maskClosable="false"
  >
    <a-form :model="formState" :rules="rules" v-bind="layout" ref="formRef">
      <a-form-item label="Año">
        <a-input v-model:value="formState.anio" />
      </a-form-item>

      <a-form-item label="Valor local">
        <a-input v-model:value="formState.valorlocal" />
      </a-form-item>

      <a-form-item label="Valor nacional">
        <a-input v-model:value="formState.valornacional" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { ref, reactive, toRaw, markRaw } from "vue";
import { notification } from "ant-design-vue";
import EstadisticoApi from "@/api/estadistico";

export default {
  props: {
    point: {
      type: Object
    },
    idtipodeficiencia: {
      type: String,
      required: true
    }
  },
  emits: ["closeModal", "fetchPoints"],
  setup(props, { emit }) {
    const formRef = ref();
    // const isEdit = computed(() => !!props.perfil.idrol);
    const formState = reactive(props.point);
    const pointStatic = props.point;

    const isEdit = !!pointStatic.idestadistico;

    const closeModal = () => {
      emit("closeModal");
      console.log("Hola cerrar modal");
    };

    const handleCancel = () => {
      emit("closeModal");
    };

    const handleOk = () => {
      formRef.value
        .validate()
        .then(async () => {
          const payload = toRaw(formState);
          payload.idtipodeficiencia = props.idtipodeficiencia;

          let response = {};
          let msgNotificacion = "";

          try {
            if (isEdit) {
              response = await EstadisticoApi.editOne(payload, pointStatic.idestadistico);
              msgNotificacion = "El punto estadístico ha sido modificado correctamente.";
            } else {
              msgNotificacion = "El punto estadístico ha sido creado satisfactoriamente.";
              response = await EstadisticoApi.addOne(payload);
            }

            if (response.status == 200) {
              notification.success({
                message: "Operación exitosa",
                description: msgNotificacion
              });

              emit("closeModal");
              emit("fetchPoints");
            }
          } catch (error) {
            notification.error({
              message: "Operación inválida",
              description: error.message
            });
          }
        })
        .catch((err) => console.log(err));
    };

    const rules = {
      anio: [
        { required: true, message: "El campo módulo debe ser seleccionado", trigger: "blur" },
        {
          max: 20,
          message: "La cantidad máxima de caracteres es de 20",
          trigger: "blur"
        }
      ],
      valorlocal: [
        {
          required: true,
          message: "El campo nombre no puede estar vacío",
          trigger: "blur"
        },
        {
          max: 20,
          message: "La cantidad máxima de caracteres es de 20",
          trigger: "blur"
        }
      ],
      valornacional: [
        {
          required: true,
          message: "El campo valor no puede estar vacío",
          trigger: "blur"
        },
        {
          max: 20,
          message: "La cantidad máxima de caracteres es de 20",
          trigger: "blur"
        }
      ]
    };

    const layout = {
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 18,
        offset: 1
      }
    };

    return {
      closeModal,
      handleCancel,
      layout,
      formRef,
      handleOk,
      rules,
      formState,
      isEdit
    };
  }
};
</script>
