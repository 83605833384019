export const columns = [
  {
    title: "N°",
    key: "index",
    width: 50,
    slots: { customRender: "rangepages" }
  },
  {
    title: "AÑO",
    dataIndex: "anio",
    key: "anio",
    width: 150,
    ellipsis: true
  },
  {
    title: "VALOR LOCAL (%)",
    dataIndex: "valorlocal",
    key: "valorlocal",
    width: 180,
    ellipsis: true
  },
  {
    title: "VALOR NACIONAL (%)",
    dataIndex: "valornacional",
    key: "valornacional",
    width: 180
  },
  {
    title: "ESTADO",
    dataIndex: "activo",
    key: "activo",
    slots: { customRender: "activo" },
    width: 120
  }
];
