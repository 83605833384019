/**
 * optionGraphic
 * @param {String} title titulo de la grafica
 * @param {Object} valoresGraficas tiene 3 ararys
 * @returns
 */
export const optionGraphic = (title = "Titulo de graficos", valoresGraficas = {}) => {
  return {
    title: {
      text: title,
      left: "center",
      textStyle: {
        fontSize: 14
      }
    },
    tooltip: {
      trigger: "axis"
    },
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        // dataView: { show: true, readOnly: false },
        magicType: { show: true, type: ["line", "bar", "stack"] },
        restore: { show: true },
        saveAsImage: { show: true }
      }
    },
    legend: {
      data: ["Nacional", "Tacna"],
      bottom: 10,
      left: 10
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: valoresGraficas.valoresEjeX
    },
    yAxis: {
      type: "value"
    },
    series: [
      {
        name: "Tacna",
        type: "line",
        stack: "Total",
        data: valoresGraficas.local,
        label: {
          show: true,
          position: "top",
          backgroundColor: "rgb(29,165,122, 0.1)",
          color: "#1DA57A",
          padding: [4, 5],
          borderRadius: 5,
          fontSize: 10
        },
        lineStyle: {
          color: "#1DA57A"
        },
        itemStyle: {
          borderWidth: 5,
          color: "#1DA57A"
        }
      },
      {
        name: "Nacional",
        type: "line",
        stack: "Total",
        data: valoresGraficas.nacional,
        label: {
          show: true,
          position: "top",
          backgroundColor: "rgba(237,122,133,0.2)",
          color: "tomato",
          padding: [4, 5],
          borderRadius: 5,
          fontSize: 10
        },
        lineStyle: {
          color: "#ED7A85",
          type: "dashed"
        },
        itemStyle: {
          borderWidth: 3,
          color: "#ED7A85"
        }
      }
    ]
  };
};
