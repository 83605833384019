<template>
  <a-form layout="vertical" class="md:flex">
    <a-form-item label="Estado" name="filter" class="mb-2 w-full md:w-44">
      <a-select v-model:value="formState.filter">
        <a-select-option value="all">Todos</a-select-option>
        <a-select-option value="1">Activos</a-select-option>
        <a-select-option value="0">Desactivados</a-select-option>
      </a-select>
    </a-form-item>

    <div>
      <button @click="applyFilters" type="submit" class="text-center px-3">
        <div class="text-3xl text-blue-400">
          <SearchOutlined />
        </div>
        <p>Buscar</p>
      </button>

      <button @click="cleanFilters" type="button" class="text-center px-3" :disabled="isDisabled">
        <div
          class="text-3xl"
          :class="[isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-purple-500']"
        >
          <ClearOutlined />
        </div>
        <p>Limpiar filtros</p>
      </button>
    </div>
  </a-form>
</template>
<script>
import { reactive, ref, toRaw } from "vue";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons-vue";
import { useStore } from "vuex";

export default {
  components: {
    // icons
    SearchOutlined,
    ClearOutlined
  },

  emits: ["fetchPoints", "onFilterData"],
  setup(props, { emit }) {
    const isDisabled = ref(true);
    const formState = reactive({
      filter: "all"
    });

    const applyFilters = () => {
      isDisabled.value = false;
      emit("onFilterData", formState.filter);
    };

    const cleanFilters = () => {
      emit("fetchPoints");
      formState.filter = "all";
      isDisabled.value = true;
    };

    return {
      applyFilters,
      cleanFilters,
      isDisabled,
      formState
    };
  }
};
</script>
